import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getCommunitiesRatesheet } from 'utils/api';
import { Col, Grid, Row, Typography } from '@hippo/components';
import { Container as LogoContainer } from 'components/deprecatedLogo/DeprecatedLogo';
import { states } from 'helpers/enums/states';
import { Divider } from 'components/Divider';
import { CommunitiesRatesheetResponse, CommunityModel } from 'types/communitiesRatesheet';
import Button from 'components/button/Button';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { formatCurrency, formatSquareFeet } from 'helpers/formatters';
import printJs from 'print-js';
import dayjs from 'dayjs';
import { ThemeProviderOverride } from 'components/ThemeProviderOverride';
import { OrgNameMapping } from 'helpers/enums/organization';
import uniqBy from 'lodash/uniqBy';
import { Spinner } from 'components/Spinner';
import { rateSheetId } from './rateSheetId';
import { AsyncAwareButton } from '../../components/AsyncAwareButton';
import { Flex } from '../../components/Flex';
import { LennarSearchInput } from '../../components/LennarSearchInput';
import { filterList } from '../divisionList/DivisionList';

let html2canvas: ((element: HTMLElement, options?: Partial<unknown>) => Promise<HTMLCanvasElement>) | undefined =
  undefined;

import('html2canvas').then((html2canvasLib) => {
  html2canvas = html2canvasLib.default;
});

const whiteLogo = '/whiteLogo.svg';

type MatchParams = {
  communityId: string;
};

const mergeSimilarRows = (communityModels: Array<CommunityModel>): Array<CommunityModel> =>
  uniqBy(
    communityModels,
    ({ plan_code, sqft, carrier_assignment }) => `${plan_code} ${sqft} ${carrier_assignment.quote.premium}`
  );

const CommunitiesRatesheet: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const [model, setModel] = useState('');
  const { communityId } = match.params;
  const dispatch = useDispatch();
  const [communitiesRatesheet, setCommunitiesRatesheet] = useState<CommunitiesRatesheetResponse | null>(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasModels, setHasModels] = useState(true);

  useEffect(() => {
    getCommunitiesRatesheet(communityId)
      .then((response) => {
        setHasModels(!!response.data.community_models.length);
        setCommunitiesRatesheet({
          ...response.data,
          community_models: mergeSimilarRows(response.data.community_models),
        });
      })
      .catch(() => {
        setHasModels(false);
      })
      .finally(() => setIsLoading(false));
  }, [communityId]);

  const stateName = communitiesRatesheet ? states[communitiesRatesheet.state.toLocaleLowerCase()] : '';

  const pushToCommunitySearch = useCallback(() => {
    dispatch(push('/lennar/search'));
  }, [dispatch]);

  useEffect(() => {
    const printablePage = document.querySelector('#printablePage') as HTMLElement | null;
    if (isPrinting && printablePage && html2canvas) {
      html2canvas(printablePage).then((canvas) => {
        setIsPrinting(false);

        canvas.setAttribute('id', 'canvas');
        document.body.appendChild(canvas);

        printJs({
          targetStyle: [],
          printable: 'canvas',
          type: 'html',
        });

        document.body.removeChild(canvas);
      });
    }
  }, [isPrinting]);

  const filteredCommunityModels = filterList<Array<CommunityModel & { display: string; value: undefined }>>(
    model,
    communitiesRatesheet?.community_models.map((model) => ({
      ...model,
      display: `${model.model_name}${model.sqft}`,
      value: undefined,
    })) ?? []
  );

  return (
    <ThemeProviderOverride orgName={OrgNameMapping.Lennar}>
      <Flex
        flexDirection='column'
        maxWidth={isPrinting ? '1050px' : 'none'}
        mx='auto'
        minHeight='100vh'
        justifyContent='space-between'
        pb='40px'
        id='printablePage'
      >
        <div>
          <Flex flexDirection='column' backgroundColor='primary500'>
            <Grid style={{ width: '100%' }}>
              <Row>
                <Col size={4 / 12} offset={1 / 12} flexDirection='row' justifyContent='space-between'>
                  <LogoContainer>
                    <img width='100%' src={whiteLogo} alt='logo' />
                  </LogoContainer>
                </Col>

                {isPrinting ? (
                  <Col size={7 / 12} />
                ) : (
                  <>
                    <Col offset={[0, 2 / 12, 2 / 12]} size={2 / 12} flexDirection='row'>
                      <Button
                        data-testid={rateSheetId.newSearchButton}
                        size='small'
                        styleType='minimal'
                        onClick={pushToCommunitySearch}
                      >
                        New Search
                      </Button>
                    </Col>

                    <Col rightOffset={1 / 12} size={2 / 12} flexDirection='row'>
                      <AsyncAwareButton
                        data-testid={rateSheetId.printButton}
                        size='small'
                        styleType='minimal'
                        onClick={() => setIsPrinting(true)}
                        showLoader={html2canvas === undefined}
                      >
                        Print
                      </AsyncAwareButton>
                    </Col>
                  </>
                )}
              </Row>
            </Grid>
          </Flex>

          {!hasModels ? (
            <Typography type='body2' color='gray800' textAlign='center' mt='50px'>
              No rates are available for this community
            </Typography>
          ) : (
            <Grid style={{ width: '100%' }} mt='44px'>
              {isLoading ? (
                <Row py='12px' center={true}>
                  <Spinner />
                </Row>
              ) : (
                <>
                  <Row>
                    <Col size={10 / 12} offset={1 / 12}>
                      <Typography type='body3' color='gray800'>
                        As Lennar’s insurance agency, we are pleased to offer tailored home insurance coverage for homes
                        in the {communitiesRatesheet?.community_name} community in {communitiesRatesheet?.city},{' '}
                        {stateName}
                      </Typography>
                    </Col>
                  </Row>
                  {isPrinting ? null : (
                    <>
                      <Flex mt='26px' />
                      <LennarSearchInput
                        label='Model'
                        name='model'
                        value={model}
                        onChange={setModel}
                        showResetButton={false}
                        onResetButtonClick={() => setModel('')}
                      />
                    </>
                  )}

                  {filteredCommunityModels.length > 0 ? (
                    <Row pt='56px' pb='12px'>
                      <Col size={4 / 12} offset={2 / 12}>
                        <Typography type='allCaps2' color='primary500'>
                          Model
                        </Typography>
                      </Col>
                      <Col size={2 / 12}>
                        <Typography type='allCaps2' color='primary500'>
                          Square feet
                        </Typography>
                      </Col>
                      <Col size={2 / 12}>
                        <Typography type='allCaps2' color='primary500' textAlign='right'>
                          Annual premium*
                        </Typography>
                      </Col>
                    </Row>
                  ) : (
                    <Flex width='100%' justifyContent='center' mt='50px'>
                      <Typography type='body4' color='gray600'>
                        No results for the “{model}”
                      </Typography>
                    </Flex>
                  )}
                  {filteredCommunityModels.map((model, i) => {
                    return (
                      <Fragment key={i}>
                        <Row py='12px'>
                          <Col size={4 / 12} offset={2 / 12}>
                            <Typography type='body4' color='gray800'>
                              {model.model_name}
                            </Typography>
                          </Col>
                          <Col size={2 / 12}>
                            <Typography type='body4' color='gray800'>
                              {formatSquareFeet(model.sqft)}
                            </Typography>
                          </Col>
                          <Col size={2 / 12}>
                            <Typography type='body4' color='gray800' textAlign='right'>
                                {formatCurrency(model.carrier_assignment.quote.premium)}
                            </Typography>
                          </Col>
                        </Row>
                        <Divider size={8 / 12} offset={2 / 12} />
                      </Fragment>
                    );
                  })}
                </>
              )}
            </Grid>
          )}
        </div>

        {hasModels && (
          <div>
            <Flex
              flexDirection='column'
              backgroundColor='gray50'
              justifyContent='space-between'
              pt='24px'
              pb='40px'
              mt='56px'
            >
              <Grid style={{ width: '100%' }}>
                <Row>
                  <Col size={6.5 / 12} offset={1 / 12}>
                    <Typography color='primary500' type='heading8' mb='24px'>
                      Home Insurance Tailored for Your Lennar Home
                    </Typography>
                    <Typography color='gray800' type='body5' mt='8px'>
                      • Designed for new construction, which could save an average of 25% compared to standard policies
                    </Typography>
                    <Typography color='gray800' type='body5' mt='8px'>
                      • Customized quotes specific to your exact Lennar home
                    </Typography>
                    <Typography color='gray800' type='body5' mt='8px'>
                      • No need to fill out lengthy insurance questionnaires. Purchase in minutes, not days.
                    </Typography>
                    <Typography color='gray800' type='body5' mt='8px'>
                      • Coverage with a top-rated insurer (rated “A- Excellent” or better by A.M. Best, the industry
                      standard)
                    </Typography>
                  </Col>
                  <Col size={3 / 12} offset={0.5 / 12}>
                    <Typography color='primary500' type='heading8' mb='24px'>
                      Standard Policy Coverage
                    </Typography>
                    <Typography color='gray800' type='body5' mt='8px'>
                      • Dwelling
                    </Typography>
                    <Typography color='gray800' type='body5' mt='8px'>
                      • Other structures{' '}
                    </Typography>
                    <Typography color='gray800' type='body5' mt='8px'>
                      • Personal Property{' '}
                    </Typography>
                    <Typography color='gray800' type='body5' mt='8px'>
                      • Additional living expenses
                    </Typography>
                    <Typography color='gray800' type='body5' mt='8px'>
                      • Personal liability
                    </Typography>
                    <Typography color='gray800' type='body5' mt='8px'>
                      • Medical payments
                    </Typography>
                  </Col>
                </Row>
              </Grid>
            </Flex>

            <Grid style={{ width: '100%' }} mt='24px'>
              <Row>
                <Col size={6 / 12} offset={1 / 12}>
                  <Typography color='primary500' type='heading5' mb='8px'>
                    Contact us today
                  </Typography>
                  <Typography color='gray800' type='allCaps1'>
                    For your customized home insurance quote
                  </Typography>
                </Col>
                <Col size={3 / 12} offset={1 / 12}>
                  <Typography color='primary500' type='heading9' mb='2px'>
                    (888) 543-6180{' '}
                  </Typography>
                  <Typography color='primary500' type='heading9' mb='2px'>
                    team@LennarInsurance.com{' '}
                  </Typography>
                  <Typography color='primary500' type='heading9' mb='2px'>
                    www.LennarInsurance.com{' '}
                  </Typography>
                </Col>
              </Row>
            </Grid>

            <Grid style={{ width: '100%' }} mt='30px'>
              <Row>
                <Col size={10 / 12} offset={1 / 12}>
                  <Divider />

                  <Typography color='gray800' type='body5' mt='8px'>
                    {communitiesRatesheet?.state} License #{communitiesRatesheet?.license.license_number}
                  </Typography>
                  <Typography color='gray800' type='body7' mt='4px'>
                    *Rates are as of {dayjs().format('MM-DD-YYYY')} and subject to change without notice and based on
                    the elevation selected. You are not required to use Lennar Insurance Agency to purchase insurance
                    for your Lennar home.
                  </Typography>
                </Col>
              </Row>
            </Grid>
          </div>
        )}
      </Flex>
    </ThemeProviderOverride>
  );
};

export default CommunitiesRatesheet;
