import { DeductibleTypes } from 'pages/coverages/type';
import { ErrorObjProps } from 'helpers/errors/errorTypes';
import { OrgNameMapping } from 'helpers/enums/organization';
import { ProductType } from '../../types/common';
interface Agent {
  agent_email: string;
  agent_name: string;
  agent_phone: string;
  division?: string;
}

interface WritableCheckoutPersonalInformation {
  mailing_address_city: string;
  mailing_address_state: string;
  mailing_address_street2: string;
  mailing_address_street: string;
  mailing_address_zip: string;
  phone_number: string | null;
}

export interface ReadableCheckoutPersonalInformation extends WritableCheckoutPersonalInformation {
  email: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
}

export interface CheckoutLoanInformation {
  company: string | null;
  contact_email: string | null;
  contact_name: string | null;
  contact_phone: string | null;
  has_no_loan?: boolean;
  loan_amount: number | null;
  loan_number: number | null;
  sales_price: number | null;
}

export type WritableCheckout = {
  additional_comments: string;
  loan_information?: CheckoutLoanInformation;
  personal_information: WritableCheckoutPersonalInformation;
};

export interface Checkout extends WritableCheckout {
  personal_information: ReadableCheckoutPersonalInformation;
}

interface Community {
  name: string | null;
}

export interface Coverages {
  a: string;
  b: string;
  c: string;
  d: string;
  e: string;
  f: string;
  pprc: string;
  deductible: string;
  deductible_amount: string;
  deductible_type: DeductibleTypes;
  hurricane_deductible: string;
  hurricane_deductible_amount: string;
  hurricane_deductible_type: DeductibleTypes;
  wind_deductible: string;
  wind_deductible_amount: string;
  wind_deductible_type: DeductibleTypes;
  wildfire_deductible: string;
  wildfire_deductible_amount: string;
  wildfire_deductible_type: DeductibleTypes;
  ordinance_or_law?: number;
  ordinance_or_law_display?: string;
  extended_rebuilding_cost: string;
  equipment_breakdown: string;
  water_backup: string;
  service_line: string;
  foundation: string;
  limited_animal_liability: string;
  quote_doc_link: string;
  sinkhole_collapse?: boolean;
}

export interface Discounts {
  fire_ext?: boolean;
  fire_sprinklers?: boolean;
  gated_community?: boolean;
  hoa_membership?: boolean;
  storm_shutters?: boolean;
  professionally_monitored_theft_alarm?: boolean;
  professionally_monitored_fire_alarm?: boolean;
  smart_home?: boolean;
}

export interface LoanInformation {
  company: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  loan_amount: string;
  loan_number: string;
}

export interface DogBreed {
  id: string;
  display: string;
}

export interface Dog {
  biteHistory?: boolean;
  breed?: string;
  mix?: string;
  comment?: string;
}

export interface ExoticPet {
  type?: string;
  biteHistory?: boolean;
  comment?: boolean;
}

export interface QuotePage {
  smart_home: boolean;
}

type NonOwnerOccupied = 'rental' | 'vacant' | 'second';

export interface Underwriting {
  non_owner_occupied: boolean | NonOwnerOccupied;
  secondary_residence: boolean;
  rental_or_vacant: boolean;
  business_on_site: boolean;
  swimming_pool: boolean;
  diving_board: boolean;
  pool_fence: boolean;
  trampoline: boolean;
  dogs: Array<Dog>;
  exotic_pets: Array<ExoticPet>;
}

export interface Model {
  is_condo: boolean;
  name: string | null;
  sqft: number | undefined;
}

export interface PersonalInformation {
  first_name: string;
  last_name: string;
  phone_number: string;
  email?: string;
}

interface PropertyInformation {
  state: string;
  city: string;
  street: string;
  street2: string;
  zip: string;
}

export interface Quote {
  a: number | null;
  b: number | null;
  c: number | null;
  carrier: string;
  d: number | null;
  deductible: number;
  e: number | null;
  f: number | null;
  hurricane_deductible: string;
  id: string;
  initial_quote_date: string;
  premium: number;
  product: ProductType;
  quote_amount: number;
  rebuilding_cost?: number;
  water_backup: number | null;
  wind_deductible: string;
  wildfire_deductible: string;
  type: string;
}

interface WhiteLabel {
  agency_name: string;
  copyright: string;
  license_name: string;
  license_number: string;
  state: string;
  logo?: string;
}

export enum SmartHomeKit {
  Ringflo = 'ringflo_self',
  Kangaroo = 'kangaroo_self',
  Ring = 'ring_self',
}

export interface ConsentData {
  agent: Agent;
  checkout: Checkout;
  community: Community;
  coverages: Coverages;
  discounts: Discounts;
  loan_information: LoanInformation;
  model: Model;
  personal_information: PersonalInformation;
  property_information: PropertyInformation;
  quote: Quote;
  underwriting: Underwriting;
  white_label: WhiteLabel;
  reference_id: string;
  smart_home_kit: boolean;
  is_smart_home_quote_feature_turned_on: boolean;
  customer_approval: boolean;
  organization_name: OrgNameMapping;
  organization: Organization;
  smart_home_automation: {
    smart_home_active_discount_fire: string | null;
    smart_home_active_discount_theft: string | null;
    smart_home_active_discount_water: string | null;
    smart_home_ordering_info_selected_kit: SmartHomeKit;
  };
}

export interface ConsentOptions {
  dog_breeds_options?: Array<DogBreed>;
}

export interface ConsentState {
  data: ConsentData | null;
  options?: ConsentOptions;
  params?: { quoteId: string };
  isFetching?: boolean;
  isFetched?: boolean;
  isSaving?: boolean;
  isSaved?: boolean;
  error: ErrorObjProps;
}

export interface Organization {
  communities_enabled: boolean;
  name: OrgNameMapping;
}
