import { useEffect, useState } from 'react';
import { Logo } from '../../components/Logo';
import { GetMyPolicyButton } from '../coverages/components/GetMyPolicyButton';
import styled from 'styled-components';
import useGetConsent from '../../helpers/useGetConsent';
import useQuoteId from '../../helpers/use-quote-id';
import useAssignedQuote from '../../helpers/useAssignedQuote';
import { useReferralFlow } from './Landing';
import { useModelId } from '../../helpers/use-route-query';
import { organizationImages } from '../../utils/organizationImages';
import { OrgNameMapping } from '../../helpers/enums/organization';
import { PageType } from '../../helpers/enums/pageType';
import { useResponsive } from '../../helpers/useResponsive';
import { Flex } from '../../components/Flex';
import { useLandingPageCTAText } from '../../experiments/hooks/useLandingPageCTAText';
import { Typography } from '@hippo/components';
import { landingId } from './landingId';

export const FixedContainer = styled.div`
  background-color: white;
  position: fixed;

  left: 0;
  right: 0;

  z-index: 1;
  padding: 5px 0;
  display: flex;

  box-shadow: 0px 4px 20px 0px #00000040;
`;

const useElementVisibility = (element: HTMLElement | null) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!element) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      }
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [element]);

  return isVisible;
};

const mobileCtaWrapperWidth = '100px';

type FixedCtaButtonProps = {
  buttonRef: React.RefObject<HTMLDivElement>;
  openRedirectModal: () => void;
  pageType: PageType;
  premiumMonthly: string;
  premium: string;
};
export const FixedCtaButton = ({
  buttonRef,
  pageType,
  openRedirectModal,
  premiumMonthly,
  premium,
}: FixedCtaButtonProps) => {
  const consent = useGetConsent();
  const quoteId = useQuoteId();
  const modelId = useModelId();
  const quote = useAssignedQuote();
  const isReferralFlow = useReferralFlow(quote.data?.organization.communities_enabled, modelId);
  const consentOrgName = consent.data?.organization.name;
  const images = organizationImages[consentOrgName ?? OrgNameMapping.Default];
  const organizationName = consentOrgName || quote.data?.organization.name;
  const carrierName = consent.data?.quote.carrier;

  const { isDesktop } = useResponsive();

  const showNavbar = useElementVisibility(buttonRef.current);

  const landingPageCTAText = useLandingPageCTAText(true);

  useEffect(() => {
    document.body.style.paddingBottom = showNavbar ? mobileCtaWrapperWidth : 'unset';

    return () => {
      document.body.style.paddingBottom = 'unset';
    };
  }, [showNavbar]);

  if (!showNavbar) {
    return null;
  }

  const CtaButton = (
    <GetMyPolicyButton
      buttonClick={isReferralFlow ? openRedirectModal : undefined}
      isCarrierHippo={carrierName === 'hippo'}
      smartHomeKit={isReferralFlow ? false : consent.data?.smart_home_kit || false}
      pageType={pageType}
      quoteId={isReferralFlow ? '' : quoteId}
      buttonSize={!isDesktop ? 'medium' : 'small'}
      width={!isDesktop ? '100%' : 'auto'}
    >
      {landingPageCTAText}
    </GetMyPolicyButton>
  );

  return (
    <FixedContainer
      style={{
        top: !isDesktop ? 'auto' : 0,
        bottom: !isDesktop ? 0 : 'auto',
      }}
      data-testid={landingId.fixedCtaButtonWrapper}
    >
      {!isDesktop ? (
        <Flex
          width='100%'
          height={mobileCtaWrapperWidth}
          margin='auto'
          paddingX='15px'
          flexDirection='column'
          justifyContent='space-between'
        >
          <Flex alignItems='center'>
            <Typography type='allCaps2' color='primary500' marginTop='9px' marginRight='5px'>
              Premium
            </Typography>
                <Typography type='body2'>
                  <sup>$</sup>
                  {premiumMonthly}
                </Typography>
                <Typography type='body5' color='gray600' marginTop='2px' marginLeft='7px'>
                  <sup>/mo ($</sup>
                  {premium}
                  <sub>/yr)</sub>
                </Typography>
          </Flex>

          <Flex display='flex'>{CtaButton}</Flex>
        </Flex>
      ) : (
        <Flex width='100%' maxWidth='1440px' height='48px' margin='auto' paddingX='20px'>
          {organizationName ? (
            <Logo
              logoUrl={images.logoDefault}
              organizationName={organizationName}
              logoDefaultUrl={images.logoDefault}
              logoMobileUrl={images.logoMobile}
            />
          ) : null}

          <Flex minWidth='300px' display='flex' justifyContent='flex-end'>
            {CtaButton}
          </Flex>
        </Flex>
      )}
    </FixedContainer>
  );
};
